@import '../../../styles/variables';
.avatar-title-container{
    z-index: 20;
    width: 100%;
    height: 0;
    background-color: var(--playground-1-color);
    font-size: var(--text-bubble-font-size);
    overflow: hidden;
    // display: none;
}
.avatar-title-container.open{
    height: var(--avatar-title-height);
    transition: var(--transition-avatar-title);
    transition-property: height;
}

@keyframes avatar-title-marquee{
    0%{transform: translateX(100%);}
    100%{transform: translateX(-100%);}
}

.avatar-title-inner-container{
    display: flex;
    height: var(--avatar-title-height);
    color: var(--text-color);
    justify-content: center;
    align-items: center;
    &.marquee_shown {
        justify-content: left;
    }
}


.avatar-title-banner {
    display: inline;
    width:auto;
    white-space: nowrap;
    box-sizing: border-box;
    &.marquee{
        animation: avatar-title-marquee var(--avatar-title-duration) linear infinite;
    }
}


