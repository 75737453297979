/* Set color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set text-color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set color to all accent components (e.g., card headers, etc.)*/
#webview-container #related-questions-container-float {
  padding: 0px 20px 0px 20px;
  margin: 0 5px;
  clear: both;
}
#webview-container .related-questions-inner-float {
  color: var(--text-color);
  margin-left: 0px;
  float: left;
  border-radius: 15px;
  margin-bottom: 10px;
  word-break: break-word;
  border: 1px dashed var(--playground-3-color);
  padding: 7px 10px 8px 19px;
}
#webview-container .related-questions-inner-float:focus {
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}
#webview-container .related-questions-inner-float div,
#webview-container .related-questions-inner-float p,
#webview-container .related-questions-inner-float span {
  color: inherit;
}
#webview-container .related-questions-title,
#webview-container .keywords-title {
  font-weight: bold;
  font-size: var(--font-sub-title-size);
  text-decoration: underline;
  text-underline-offset: 4px;
}
#webview-container .keywords-container {
  margin-bottom: 15px;
}
#webview-container .keywords-title {
  text-decoration: underline;
  text-underline-offset: 4px;
}
#webview-container .keyword-list-container {
  display: flex;
  gap: 10px;
  flex-flow: wrap;
  margin-bottom: 5px;
}
#webview-container .keyword-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #aaa;
  padding: 0 10px;
  color: var(--primary-action-color);
  text-decoration: none !important;
}
#webview-container .keyword-remove-text {
  white-space: nowrap;
}
#webview-container .keyword-remove-icon {
  cursor: pointer;
  margin-top: 1px;
}
#webview-container .suggested-keywords-container {
  font-size: var(--font-sub-title-size);
}
#webview-container .suggested-keyword {
  text-decoration: underline;
  text-underline-offset: 2px;
  padding: 0 2px;
}
#webview-container .suggested-keyword:hover {
  cursor: pointer;
  font-weight: bold;
}
#webview-container .related-questions-inner-item {
  /* padding: 6px 0 25px 0; */
  /* margin: 0 auto; */
  height: 50px;
  border-top: 1px solid var(--playground-2-color);
  /* place-items: center; */
  /* display: inline-table; */
  /* top: 50%; */
  /* margin-top: 10px; */
  /* position: absolute; */
  display: table;
  width: 100%;
}
#webview-container .related-questions-inner-item.main-border {
  border-top: none;
}
#webview-container .related-questions-inner-item .context-menu p {
  font-size: var(--related-questions-answer-font-size);
}
#webview-container .related-questions-inner-item:hover .related-questions-text {
  text-decoration: underline;
  font-weight: bold;
}
#webview-container .related-questions-text {
  width: 100%;
  padding: 8px 0;
  /* float: left; */
  font-size: var(--related-questions-text-font-size);
  display: table-cell;
  /* height: 20px; */
  /* position: absolute; */
  vertical-align: middle;
  cursor: pointer;
  cursor: hand;
}
#webview-container .related-questions-btn-wrapper {
  display: table-cell;
  vertical-align: middle;
}
#webview-container .related-questions-btn-wrapper button:focus {
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}
#webview-container .related-questions-title-btn-wrapper {
  float: right;
  bottom: 5px;
  position: relative;
  display: table-cell;
}
#webview-container .related-questions-title-btn {
  float: right;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  cursor: hand;
  outline: none;
  font-size: var(--card-button-font-size);
  background-color: transparent;
  border: none;
  width: 19px;
  height: 19px;
  padding: 0px;
}
#webview-container .related-questions-title-btn.up-arrow {
  background-color: transparent;
}
#webview-container .related-questions-title-btn.up-arrow img {
  width: 12px;
  margin-top: -7px;
}
#webview-container .related-questions-title-btn.down-arrow {
  border: none;
}
#webview-container .related-questions-title-btn.down-arrow img {
  transform: rotate(180deg);
  width: 12px;
  margin-top: -4px;
}
#webview-container .related-questions-title-btn .kai-tooltip-text {
  right: -5px !important;
  bottom: calc(-100% - 7px);
}
#webview-container .related-questions-title-btn .kai-tooltip-text::after {
  right: 10px;
  left: initial !important;
  bottom: 100%;
  top: initial;
  border-color: transparent transparent var(--right-message-color) transparent;
}
