<template>
  <div
    class="kai-answers-bottom-bar"
    :class="{live_chat_open_up:this.$parent.isLiveChatStarted && !this.$parent.useWidgetMode, live_chat_open_down:this.$parent.isLiveChatStarted && this.$parent.useWidgetMode, bottomBarWidget: this.$parent.useWidgetMode}"
  >
    <ChipPanelQbar
      v-if="this.$parent.chipItemLength > 0"
      :response="this.$parent.response"
    />
    <button
      class="fresh-start-btn"
      :class="{
        'fresh-start-btn-widget':this.$parent.useWidgetMode,
        'fresh-start-btn-not-widget': !this.$parent.useWidgetMode && !this.$parent.displaySource,
        'display-source-fresh-start-btn': !this.$parent.useWidgetMode && this.$parent.displaySource,
      }"
      @click="this.$parent.freshStartClick"
    >
      Fresh Start
    </button>
    <transition name="recording-fade">
      <div
        class="kai-textarea"
        :class="{'kai-textarea-widget':this.$parent.useWidgetMode}"
      >
        <input
          id="kai-input-prompt-area"
          ref="kaiInputPromptArea"
          v-model="$parent.userRequest.message"
          class="kai-input-prompt"
          tabindex="0"
          autocomplete="off"
          :dir="$store.state.enableRTL ? 'rtl' : 'auto'"
          :placeholder="this.$parent.inputPlaceHolder"
          :aria-label="this.$parent.inputPlaceHolder"
          @keyup="$parent.inputMessageChange"
          @keydown.enter="$parent.sendMessage"
        >
      </div>
    </transition>
    <button
      class="kai-send-button"
      :aria-label="this.$parent.translations.sendMessageButton"
      tabindex="0"
      @click="this.$parent.sendMessage"
    >
      <img
        :src="this.$parent.kaiAnswersSendIcon"
        alt=""
      >
    </button>
  </div>
</template>

<style lang="less" src='./styles/KaiAnswersBottomBar.less' scoped>
</style>

<style scoped>
  .kai-chat-message {
    max-width: 70% !important;
  }
</style>
