@import '../../../styles/variables';

.multi-select-container-card .kai-card-button {
    cursor: pointer;
    margin: 0;
}
.checkmark-carousel-select{
  position:absolute;
  top: 7px;
  left: 4%;
  display:none;
}
.slider{
  height:auto;
}

.kai-card-select-carousel {
  margin: 4px 0;
}