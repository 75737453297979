
/* ======================================================= 
 *      ---------------------------------
 *      CONTENTS
 *      ---------------------------------
 *
 *      [A] DEFAULT INPUT
 *      [B] MODAL
 *      [C] MODAL CONTROLS
 *      [D] MODAL BACKGROUND
 *      [E] SHOW CLASS
 *      [F] DATE PICKER
 *      [G] EFFECTS
 *      [H] LANDSCAPE
 *    
 * ======================================================= */

/* ====================================================================== *
        [A] DEFAULT INPUT
 * ====================================================================== */      

  .default-input{
      box-sizing: border-box; 
      background: #fff;
      border: 1px solid #e4e4e4;
      height: 42px;
      line-height: 42px;
      display: block;
      width: 100%;  
      padding: 0px 12px;
      cursor: pointer;
  }

/* ====================================================================== *
        [B] MODAL
 * ====================================================================== */      

  .dateCalendarScrollHide{
    overflow-y: hidden;
  }

  .dateCalendar-module{
      width: 100%;
      max-width: 667px;
      height: auto;
      max-height: 90%;
      padding: 0px;
      position: fixed;
      top: 50%;
      left: 50%;
      z-index: 99999999;
      visibility          : hidden;

      -webkit-transform   : translateX(-50%) translateY(-50%);
      -moz-transform      : translateX(-50%) translateY(-50%);
      -ms-transform       : translateX(-50%) translateY(-50%);
      transform           : translateX(-50%) translateY(-50%);
  }

  .dateCalendar-container{
      background: white;
      position: relative;
      border-radius: 10px;
  }

  @media only screen and (max-width: 500px) {
    .dateCalendar-module{
      top: initial !important;
      bottom: 0 !important;

      -webkit-transform   : translateX(-50%) translateY(-0%) !important;
      -moz-transform      : translateX(-50%) translateY(-0%) !important;
      -ms-transform       : translateX(-50%) translateY(-0%) !important;
      transform           : translateX(-50%) translateY(-0%) !important;
    }
  }

  .dateCalendar-inner-container{
    padding: 10px;
    border-radius: 10px;
  }

  @media only screen and (max-width: 500px) {
    .dateCalendar-inner-container{
      padding: 5px;
    }    
  }

/* ====================================================================== *
        [C] MODAL CONTROLS
 * ====================================================================== */      

  .dateCalendar-nav{
    text-align: center;
    box-sizing: border-box;
  }
  .dateCalendar-nav>a{
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 97%;
    height: 33px;
    padding: 15px 5px;
    color: rgb(51, 51, 51);
    text-decoration: none;
    font-weight: 600;
    border-top: 1px solid #eee;
    /* border-bottom-left-radius: 9px; */
    /* border-bottom-right-radius: 9px; */
    border-radius: 5px;
    line-height: 0px;
    margin-bottom: 10px;
  }
  .dateCalendar-nav>a:hover{
    background: #eee;
    cursor: pointer;
  }

/* ====================================================================== *
        [D] MODAL BACKGROUND
 * ====================================================================== */    

  .dateCalendar-overlay{
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99999998;
      background: rgba(60, 59, 59, 0.95);
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      cursor: default;

      -webkit-transition  : all 0.3s;
      -moz-transition     : all 0.3s;
      transition          : all 0.3s;
  }

/* ====================================================================== *
        [E] SHOW CLASS
 * ====================================================================== */      

  .dc-visible{
    visibility: visible;
  }

  .dc-visible ~ .dateCalendar-overlay {
    opacity: 1;
    visibility: visible;
  }


/* ====================================================================== *
        [F] DATE PICKER
 * ====================================================================== */      

  div.datepicker table, 
  div.datepicker-inline{
      width: 100%;
      font-size: 20px;
  }

  .dc-table > thead > tr > th.dow{
      color: #4a4a4a;
  }

  .dc-table > thead > tr > th{
      font-weight: 600 !important;
  }

  .dc-table > thead > tr > th, 
  .dc-table > tbody > tr > th, 
  .dc-table > tfoot > tr > th, 
  .dc-table > thead > tr > td, 
  .dc-table > tbody > tr > td, 
  .dc-table > tfoot > tr > td {
      padding: 10px;
  }

  div.dateCalendar-template table tr td.day{
      color: #303641;
  }

  .dateCalendar-template table tr td.disabled, .dateCalendar-template table tr td.disabled:hover {
    cursor:not-allowed;
    color: #dddddd !important;
  }

  div.dateCalendar-template table tr td.old, 
  div.dateCalendar-template table tr td.new {
      color: #dddddd;
  }

  div.dateCalendar-template table tr td.active{
      color: #fff;
  }

  .dateCalendar-template table tr td.active.active, .dateCalendar-template table tr td.active.highlighted.active, .dateCalendar-template table tr td.active.highlighted:active, .dateCalendar-template table tr td.active:active,
  .dateCalendar-template table tr td span.active.active, .dateCalendar-template table tr td span.active.disabled.active, .dateCalendar-template table tr td span.active.disabled:active, .dateCalendar-template table tr td span.active.disabled:hover.active, .dateCalendar-template table tr td span.active.disabled:hover:active, .dateCalendar-template table tr td span.active:active, .dateCalendar-template table tr td span.active:hover.active, .dateCalendar-template table tr td span.active:hover:active {
      background: #303641 !important;
      color: #fff;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  
/* ====================================================================== *
        [G] EFFECTS
 * ====================================================================== */   
  
  /* Effect 0: Fade in */

  .dc-fx-0 .dateCalendar-container{
    opacity: 0;

    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  .dc-visible.dc-fx-0 .dateCalendar-container{
    opacity: 1;
  }

  /* Effect 1: Fade in and scale up */

  .dc-fx-1 .dateCalendar-container{
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
    opacity: 0;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }
  .dc-visible.dc-fx-1 .dateCalendar-container{
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  
  /* Effect 2: Slide from the right */

  .dc-fx-2 .dateCalendar-container {
    -webkit-transform: translateX(20%);
    -moz-transform: translateX(20%);
    -ms-transform: translateX(20%);
    transform: translateX(20%);
    opacity: 0;
    -webkit-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
    -moz-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
    transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  }
  .dc-visible.dc-fx-2 .dateCalendar-container {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }

  /* Effect 3: Slide from the bottom */

  .dc-fx-3 .dateCalendar-container {
    -webkit-transform: translateY(20%);
    -moz-transform: translateY(20%);
    -ms-transform: translateY(20%);
    transform: translateY(20%);
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }
  .dc-visible.dc-fx-3 .dateCalendar-container {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

/* ====================================================================== *
        [H] SMALLER VERSION OF CALENDAR FOR LANDSCAPE
 * ====================================================================== */      

@media screen and ( max-height: 500px ){
    .dateCalendar-module {
      max-width: 500px;
    }

    .dateCalendar-nav>a{
      padding: 9px 5px;
      font-size: 10px;
    }

    div.dateCalendar-template table, 
    div.datepicker-inline{
        width: 100%;
        font-size: 12px;
    }

    .dc-table > thead > tr > th, 
    .dc-table > tbody > tr > th, 
    .dc-table > tfoot > tr > th, 
    .dc-table > thead > tr > td, 
    .dc-table > tbody > tr > td, 
    .dc-table > tfoot > tr > td {
      padding: 5px;
    }
}