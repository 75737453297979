/* Set color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set text-color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set color to all accent components (e.g., card headers, etc.)*/
.cust-msg-line {
  display: flex;
  width: 100%;
}
.cust-msg-left {
  float: left;
  width: 100%;
}
.cust-msg-right {
  float: right;
  font-weight: 700;
  width: 50%;
  text-align: right;
}
.cust-msg-align-right {
  text-align: right;
}
.cust-msg-title {
  font-weight: bold;
}
.cust-msg-label {
  font-style: italic;
}
.cust-msg-green {
  color: green;
}
.cust-msg-red {
  color: red;
}
