@import '../../../styles/variables';

.live-chat-widget {
    position: absolute;
    width: 100%;
    bottom: 66px;
    height: 0;
    visibility: hidden;
    background-color: #ffffff;
    // overflow: hidden; // test removing overflow hidden to allow tooltips to overflow
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.16);
    // display: none;
}

.live-chat-video-widget.live_chat_open_up {
    border-top: 1px solid #cecece;

}

.live-chat-video-widget.live_chat_open_down {
    transition: var(--transition-live-chat-widget);
    transition-property: bottom;
    visibility: visible;
    height: var(--live-chat-widget-height);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top: 1px solid #cecece;

}

@media screen and (min-width: 435px) {
    .live-chat-video-widget.live_chat_open_down {
        transition-delay: 0.5s;
        bottom: 7px;
        width: 385px;
        right: 30px;
        position: fixed;
        z-index: 100001;
    }

    .webview_container_widget .live-chat-video-widget.live_chat_open_up {
        display: none;
    }
}

@media screen and (max-width: 435px) {
    .live-chat-video-widget.live_chat_open_down {
        height: var(--live-chat-widget-height);
        display: none;
    }

    .webview_container_widget .live-chat-video-widget.live_chat_open_up {
        height: var(--live-chat-widget-height);
        visibility: visible;
        bottom: 0px;
        transition: var(--transition-live-chat-widget);
        transition-property: height;
    }
}

.live-chat-video-widget.live_chat_open_up:not(.webview_container_widget .live-chat-video-widget.live_chat_open_up) {
    height: var(--live-chat-widget-height);
    visibility: visible;
    bottom: 0px;
    transition: var(--transition-live-chat-widget);
    transition-property: height;
    z-index: 20;
}

.live-chat-video-widget-inner-container {
    display: flex;
    height: var(--live-chat-widget-height);
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
    justify-content: space-between;
    align-items: center;
}

.live-chat-video-widget-feature-container {
    display: flex;
    height: var(--live-chat-widget-height);
    justify-content: flex-start;
    align-items: center;
}

// .live-chat-button:hover {
//     background-color: var(--live-chat-btn-hover-color);
//     color: var(--hover-text-color);
// }



.live-chat-end-call-button,
.live-chat-mute-button-pressed,
.live-chat-video-button-pressed,
.live-chat-desktop-sharing-button-pressed {
    background-color: var(--live-chat-end-button-color);
}

.live-chat-end-call-button:hover,
.live-chat-mute-button-pressed:hover,
.live-chat-video-button-pressed:hover,
.live-chat-desktop-sharing-button-pressed:hover
 {
    background-color: var(--live-chat-end-button-color-hover);
}

// .live-chat-image {
//     width: 35px;
//     border-radius: 100%;
// }

// .live-chat-image img {
//     height: 20px;
//     width: 20px;
//     margin: 8px 0;
// }

// .live-chat-widget-panel {
//     position: absolute;
//     display: flex;
//     flex-direction: column;
//     bottom: 0;
//     background-color: var(--component-background-color);
//     width: 100%;
//     height: 0px;
//     z-index: 20;
//     transition: all 0.5s ease-in-out;
//     transition-property: height;
//     // transition-delay: 0.5s;
//     border-bottom-left-radius: 20px;
//     border-bottom-right-radius: 20px;

//     &.small_panel {
//         height: 475px;
//         // display: flex;
//     }

//     &.big_panel {
//         bottom: 0;
//         height: 100vh;
//         // display: flex;
//     }
// }


// @media only screen and (min-height: 750px) and (min-width: 435px) {

//     // limit "big" panel height to 694px only when webview is in widget mode,
//     // which is indicated by the live chat widget being open down (under the webview widget)
//     .live-chat-video-widget.live_chat_open_down .live-chat-widget-panel.big_panel {
//         height: 694px;
//     }

// }

// .live-chat-panel-webrtc-container {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     overflow: hidden;
//         visibility: hidden;
// }
// .live-chat-widget-panel.big_panel .live-chat-panel-webrtc-container {
//     visibility: visible;
//     overflow: initial
// }
// .live-chat-panel-inner-container {
//     display: flex;
//     flex-direction: column;
//     height: 0;
//     align-items: center;
//     position: relative;
//     overflow: hidden;
//     transition: all 0.2s ease-in;
//     transition-delay: 0.2s;
//     transition-property: height;
//     justify-content: space-evenly;
// }

// .live-chat-widget-panel.small_panel .live-chat-panel-inner-container,
// .live-chat-widget-panel.big_panel .live-chat-panel-inner-container {
//     height: 100%;
// }

// .live-chat-panel-image {
//     margin-top: 75px;

//     .svg-wrapper {
//         color: var(--primary-action-color);
//     }
// }

// .live-chat-panel-subtitle {
//     margin-top: 60px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-direction: column;
//     font-weight: bold;
//     font-size: var(--font-title-size);
// }

// .live-chat-panel-description {
//     margin: 20px 20px;
//     display: flex;
//     text-align: center;
// }
