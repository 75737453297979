/* Set color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set text-color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set color to all accent components (e.g., card headers, etc.)*/
.live-chat-widget {
  position: absolute;
  width: 100%;
  bottom: 66px;
  height: 0;
  visibility: hidden;
  background-color: #ffffff;
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.16);
}
.live-chat-video-widget.live_chat_open_up {
  border-top: 1px solid #cecece;
}
.live-chat-video-widget.live_chat_open_down {
  transition: var(--transition-live-chat-widget);
  transition-property: bottom;
  visibility: visible;
  height: var(--live-chat-widget-height);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: 1px solid #cecece;
}
@media screen and (min-width: 435px) {
  .live-chat-video-widget.live_chat_open_down {
    transition-delay: 0.5s;
    bottom: 7px;
    width: 385px;
    right: 30px;
    position: fixed;
    z-index: 100001;
  }
  .webview_container_widget .live-chat-video-widget.live_chat_open_up {
    display: none;
  }
}
@media screen and (max-width: 435px) {
  .live-chat-video-widget.live_chat_open_down {
    height: var(--live-chat-widget-height);
    display: none;
  }
  .webview_container_widget .live-chat-video-widget.live_chat_open_up {
    height: var(--live-chat-widget-height);
    visibility: visible;
    bottom: 0px;
    transition: var(--transition-live-chat-widget);
    transition-property: height;
  }
}
.live-chat-video-widget.live_chat_open_up:not(.webview_container_widget .live-chat-video-widget.live_chat_open_up) {
  height: var(--live-chat-widget-height);
  visibility: visible;
  bottom: 0px;
  transition: var(--transition-live-chat-widget);
  transition-property: height;
  z-index: 20;
}
.live-chat-video-widget-inner-container {
  display: flex;
  height: var(--live-chat-widget-height);
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  justify-content: space-between;
  align-items: center;
}
.live-chat-video-widget-feature-container {
  display: flex;
  height: var(--live-chat-widget-height);
  justify-content: flex-start;
  align-items: center;
}
.live-chat-end-call-button,
.live-chat-mute-button-pressed,
.live-chat-video-button-pressed,
.live-chat-desktop-sharing-button-pressed {
  background-color: var(--live-chat-end-button-color);
}
.live-chat-end-call-button:hover,
.live-chat-mute-button-pressed:hover,
.live-chat-video-button-pressed:hover,
.live-chat-desktop-sharing-button-pressed:hover {
  background-color: var(--live-chat-end-button-color-hover);
}
