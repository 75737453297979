/* Set color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set text-color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set color to all accent components (e.g., card headers, etc.)*/
.kai-screen-intro .kai-circle {
  display: block;
}
.kai-screen-intro .introScreenTitle {
  display: block;
}
.kai-screen-intro p {
  padding: 0px;
}
.kai-screen-intro .kai-circle {
  margin: auto;
  background-color: #ccc;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}
.kai-screen-intro {
  padding: 20px 20px 50px 20px;
  margin: auto;
  width: 50%;
  text-align: center;
}
.kai-screen-intro h1 {
  font-weight: normal;
  padding: 10px;
  margin: 0;
  font-size: 16px;
}
.kai-screen-intro p {
  color: #999;
  font-size: 12px;
  padding: 5px;
  margin: 0;
}
