/* Set color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set text-color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set color to all accent components (e.g., card headers, etc.)*/
.kai-modal-header .close:hover,
.kai-modal-header .close:focus {
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}
.kai-inline-kai-modal-content-wrapper {
  display: flex;
}
.kai-inline-kai-modal-content,
.kai-inline-modal-image-content-wrapper {
  display: inline-block;
}
.inline-popup-content button.kai-card-button.kai-card-outline-button {
  background-color: var(--primary-action-text-color) !important;
  color: var(--primary-action-color) !important;
  border: var(--card-item-border-size) solid var(--primary-action-color) !important;
  font-size: var(--card-button-font-size) !important;
}
.inline-popup-content button.kai-card-button.kai-card-outline-button:hover {
  background-color: var(--hover-suggestion-color) !important;
  color: var(--hover-color) !important;
  border: var(--card-item-border-size) solid var(--hover-color) !important;
}
.inline-popup-container {
  z-index: 1000;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inline-popup-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1001;
  pointer-events: auto;
  background-color: rgba(1, 1, 1, 0.4);
}
.inline-popup-wrapper.inline-popup-toast {
  background-color: transparent;
  pointer-events: none;
}
.inline-popup-wrapper .inline-popup-content {
  padding-top: var(--header-bar-height);
  display: none;
  z-index: 1002;
  height: 100%;
}
.inline-popup-wrapper .inline-popup-content .kai-modal-title {
  font-size: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.inline-popup-wrapper .inline-popup-content .kai-inline-modal-header {
  display: flex !important;
  flex-direction: row;
  justify-content: center!important;
  align-items: center!important;
}
.inline-popup-wrapper .inline-popup-content .kai-modal-content {
  margin-top: 42px;
}
.inline_popup_widget {
  height: 100%;
  width: inherit;
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inline_popup_widget .inline-popup-wrapper {
  position: relative;
}
.inline_popup_widget .inline-popup-wrapper .inline-popup-content {
  margin-top: 0!important;
}
.inline-popup-wrapper .inline-popup-content.kai-modal-dialog {
  width: 90%;
  margin: auto;
}
.inline-popup-wrapper .inline-popup-content.inline_popup_content_open {
  display: block;
}
@media screen and (max-width: 385px) {
  .inline-popup-wrapper {
    width: 100%;
  }
}
.inline-popup-wrapper .inline-popup-content .kai-inline-modal-header.kai-modal-header button.close {
  padding: 0.5rem;
  margin: -0.75rem -0.75rem -1rem -1rem;
  align-self: flex-start;
}
.inline-popup-wrapper .inline-popup-content .kai-inline-modal-footer.kai-modal-footer {
  border-top: unset;
  justify-content: center;
}
.inline-popup-toast.inline-popup-wrapper {
  width: auto;
}
.inline-popup-toast .kai-modal-content {
  background-color: rgba(0, 0, 0, 0.6) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s;
}
.inline-popup-toast .kai-modal-content.kai-modal-content-show {
  opacity: 1;
}
.inline-popup-toast .kai-modal-content .kai-toast-message {
  margin: 10px;
  text-align: center;
  color: white;
}
