/* Set color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set text-color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set color to all accent components (e.g., card headers, etc.)*/
#webview-container {
  /* Fixes for responsive webview */
}
#webview-container #n-best {
  position: fixed;
  color: var(--text-color);
  bottom: 13px;
  left: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #cecece;
}
#webview-container .right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
#webview-container #n-best {
  position: absolute;
  z-index: 99;
}
#webview-container #nbest-container-float {
  padding: 0px 20px 0px 20px;
  margin: 5px;
  clear: both;
  margin-bottom: 200px;
}
#webview-container .nbest-inner-float {
  background: var(--left-message-color);
  color: var(--left-message-text-color);
  margin-left: 0px;
  float: left;
  border-radius: 10px 10px 10px 0px;
  margin-bottom: 10px;
  word-break: break-word;
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  overflow: hidden;
  padding: 7px 10px 8px 19px;
  max-width: 637px;
}
#webview-container .nbest-inner-float:focus {
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}
#webview-container .nbest-inner-title {
  font-weight: bold;
  font-size: 17px;
  padding: 10px 0;
  color: var(--text-color);
}
#webview-container .nbest-inner-item {
  /* padding: 6px 0 25px 0; */
  /* margin: 0 auto; */
  height: 66px;
  border-top: 1px solid #999;
  /* place-items: center; */
  /* display: inline-table; */
  /* top: 50%; */
  /* margin-top: 10px; */
  /* position: absolute; */
  display: table;
  width: 100%;
}
#webview-container .nbest-inner-question {
  width: 69%;
  /* float: left; */
  font-size: var(--nbest-text-font-size);
  display: table-cell;
  /* height: 20px; */
  /* position: absolute; */
  vertical-align: middle;
  color: var(--text-color);
}
#webview-container .nbest-inner-btn {
  float: right;
  text-align: center;
  background-color: var(--primary-action-color);
  padding: 10px;
  color: white;
  border-radius: 5px;
  padding: 6px 20px;
  font-weight: bold;
  cursor: pointer;
  cursor: hand;
  border: none;
  outline: none;
  font-size: 14px;
}
#webview-container .nbest-btn-wrapper {
  display: table-cell;
  vertical-align: middle;
}
#webview-container .nbest-btn-wrapper button:focus {
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}
#webview-container .nbest-title-btn-wrapper {
  float: right;
  bottom: 5px;
  position: relative;
  display: table-cell;
}
#webview-container .nbest-title-btn {
  float: right;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  cursor: hand;
  outline: none;
  font-size: 14px;
  background-color: var(--primary-action-color);
  color: white;
  border: none;
  width: 72px;
  height: 33px;
  padding-top: 5px;
}
#webview-container .nbest-title-btn:focus {
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}
#webview-container .nbest-title-btn.up-arrow {
  background-color: transparent;
  color: var(--secondary-action-color);
}
#webview-container .nbest-title-btn.up-arrow i {
  transform: rotate(90deg);
  vertical-align: baseline;
}
#webview-container .nbest-title-btn.down-arrow {
  border: none;
}
#webview-container .nbest-title-btn.down-arrow i {
  width: 25px;
  height: 13px;
}
