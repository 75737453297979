@import '../../../styles/variables';

body {
    background-color: #ffffff !important;
}

#kai-answers-bottombar {
  display: flex;
  position:fixed;
  width: -webkit-fill-available;
  max-width: 940px;
  justify-content: center;
  margin: auto;
  bottom:0;
  left:0;
  right:0;
  width:100%;
  margin-bottom: 20px;
  padding: 0 5px;
  &.display-source-bottombar {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 435px) {
  .webview_container_widget #kai-answers-bottombar.live_chat_open_down{
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

#kai-answers-bottombar.live_chat_open_up:not(.webview_container_widget #kai-answers-bottombar.live_chat_open_up){
  bottom: var(--live-chat-widget-height);
  transition: var(--transition-bottom-bar);
}

@media screen and (min-width: 435px) {  
  .webview_container_widget #kai-answers-bottombar{
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}


#kai-answers-bottombar .kai-answers-bottom-bar {
  display: flex;
  width: 100%;
  position:absolute;
  height: var(--kai-answers-bottom-bar-height);
  background: var(--kai-answers-bottom-bar-color);

  textarea {
    font-size: var(--bottom-bar-font-size);
    background-color: #fff;
  }
}

.kai-textarea {
//    float: left;
   background-color: #fff;
   width: 100%;
   direction: ltr !important;
   padding: 0px 10px;
   border: 1px solid #979797 ;
   margin: 0 5px;
   border-radius: 6px;
   height: 40px;
   &.kai-textarea-widget {
    width: calc(100% - 145px);
   }
}

.kai-input-prompt {
    position: relative !important;
    top: 0;
    width: 100%;
    background-color: transparent;
    overflow: auto;
    padding: 0px;
    height: calc(var(--kai-answers-bottom-bar-height) - 33px);
    font-family: 'Lato';
    font-size: var(--bottom-bar-font-size);
    border: none;
    border-radius: 2px;
    outline: none;
    display: inline-block;
}

.kai-input-prompt::placeholder { /* Most modern browsers support this now. */
  font-style: italic;
  font-family: 'Lato';
}

.kai-send-wrapper {
   float: right;
   margin-right: 20px;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;

   &.speech{
    animation: speech-anim 2s 1
   }
}

.kai-send-button {
    background-color: #fff;
    cursor: pointer;
    float: right;
    //height: var(--bottom-bar-height);
    width: 45px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #03A96F;
    font-size: var(--bottom-bar-font-size);
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: none;
    right: 10px;
    padding: 1px 6px;
       img {
         width: 100%;
         margin-top: -2px;
       }
    z-index: 10000;
}

// .kai-send-button:hover {
//     background-color: var(--hover-color);
//     color: var(--hover-text-color);
// }

/* Fixes for responsive webview */
#kai-answers-bottombar {
  position: absolute;
  z-index: 13;
}
.kai-answers-bottom-bar {
  position: absolute;
  background-color: #ffffff;
}


.typeahead {
  background-color: var(--kai-answers-bottom-bar-color);;
}

.twitter-typeahead {
  width: 100%;
}

.kai-input-prompt.tt-input {
  width: 100%;
  padding: 0;
  top: -8px;
}

.tt-hint {
  color: #999;
  display: none;
}

.tt-menu {
  width: calc(100% + 94px);
  bottom:100% !important;
  top:initial !important;
  left: -2px !important;
  max-height: 500px;
  overflow: hidden;
  margin: 8px 0 0 0;
  padding: 8px 0;
  background-color: var(--kai-answers-bottom-bar-color);
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 8px 8px 0 0;
     -moz-border-radius: 8px 8px 0 0;
          border-radius: 8px 8px 0 0;
  -webkit-box-shadow: 0 0px 10px rgba(0,0,0,.2);
     -moz-box-shadow: 0 0px 10px rgba(0,0,0,.2);
          box-shadow: 0 0px 10px rgba(0,0,0,.2);
}

.tt-suggestion {
  padding: 3px 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 165px;
  -webkit-line-clamp: 6; /* number of lines to show */
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-weight: bold;
  font-size: var(--bottom-bar-font-size);
}

.tt-suggestion:hover {
  cursor: pointer;
  color: var(--text-color);
  background-color: var(--hover-suggestion-color);
}

.tt-suggestion.tt-cursor {
  background-color: var(--hover-suggestion-color);
  color: var(--text-color);
}

.tt-suggestion p {
  margin: 0;
}

.tt-suggestion .tt-highlight {
  font-weight: normal;
}

.webview-container .kai-answers-bottom-bar pre {
  padding: 0px !important;
  border: none !important;
}

.fresh-start-btn {
    border-radius: 6px;
    color: #03A96F;
    border: solid 1px #03A96F;
    background-color: #fff;
    padding: 6px 11px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    transition: all 0.3s ease;
    min-width: 55px;
    max-width: 120px;
    width: 30%;
    height: 40px;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
    &.fresh-start-btn-widget {
        font-size: 14px;
        min-width: 95px;
    }
}

.fresh-start-btn:hover {
    background-color: #03A96F;
    color: #ffffff;
}
