.shortcut-wrapper {
  width: 100%;
  z-index: 123;
  transition: all 0.5s ease-in-out;
}
.shortcut-wrapper hr {
  border-style: inset;
  border-width: 1px;
  opacity: 1;
  margin: 0;
  border-color: initial;
}
.shortcut_handle_open {
  top: 0;
  height: 100%;
}
.shortcut-handle img {
  position: absolute;
  top: calc(50% - 12.5px);
  left: 6px;
}
.shortcut-header {
  font-weight: bold;
  margin-top: 25px;
}
.shortcut-header:focus {
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}
.shortcut-item-wrapper {
  margin-top: 10px;
}
.shortcut-item {
  font-family: 'Lato', 'Avenir', Helvetica, Arial, sans-serif;
  background-color: #ccc;
  width: auto;
  padding: 10px;
  border-radius: 10px;
  float: left;
  font-size: 12px;
  font-weight: bold;
  margin: 5px 10px 5px 0;
}
.shortcut-item:focus {
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}
@-moz-document url-prefix() {
  .shortcut-item {
    letter-spacing: -0.02em;
  }
}
.shortcut-item:hover {
  background-color: #999;
}
.shortcut-content-header {
  font-weight: bold;
  padding: 20px 20px 0px 20px;
}
.shortcut-wrapper .shortcut-content {
  background-color: #fff;
  box-shadow: 5px 1px 10px rgba(0, 0, 0, 0.25);
}
.shortcut-content {
  padding: 20px 20px 0px 20px;
  font-size: 15px;
  height: 100%;
  overflow-y: auto;
  color: var(--text-color);
}
.shortcut-content h1 {
  padding: 0px;
  margin: 0px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.shortcut-content img {
  margin-top: -2px;
}
.shortcut-content p {
  padding: 0;
}
.shortcut-content h1,
.shortcut-content div,
.shortcut-content p,
.shortcut-content span {
  color: inherit;
}
.shortcut-content-inner {
  padding: 10px 20px 20px 20px;
}
.clear-it {
  clear: both;
}
.vm--modal {
  border-radius: 15px !important;
}
.close-modal {
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 15px;
}
.kai-mouse-pointer {
  cursor: pointer;
  cursor: hand;
}
.shortcut-container {
  bottom: var(--bottom-bar-height);
  font-size: 15px;
  width: 100%;
  z-index: 12;
}
.shortcut_widget .shortcut-wrapper {
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease-in-out;
  transition-property: max-height;
}
.shortcut-wrapper .shortcut-content {
  display: none;
}
.shortcut-wrapper .shortcut_content_open {
  display: block;
  height: 100%;
  overflow: auto;
  border-top: 1px solid #ececec;
}
.shortcut-handle img {
  top: calc(50% - 12.5px);
  left: 5px;
}
.shortcut_handle_open img {
  top: calc(50% - 12.5px);
  left: 7px;
}
.svg-heart {
  color: var(--primary-action-color);
}
.shortcut-menu-button {
  width: 30px;
  height: 100%;
  display: flex;
  color: var(--shortcut-menu-button-icon-color);
  margin-left: 10px;
  align-items: center;
}
.shortcut-menu-button svg {
  border-radius: 5px;
  width: 100%;
}
.shortcut-menu-button.shortcut_menu_open svg {
  background-color: var(--shortcut-menu-button-background-color-pressed);
  color: var(--shortcut-menu-button-icon-color-pressed);
}
.shortcut-menu-button.shortcut_menu_open svg:focus {
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}
.shortcut-menu-button svg:hover {
  background-color: var(--hover-color);
  color: var(--shortcut-menu-button-icon-color-pressed);
}
