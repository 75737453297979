/* Set color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set text-color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set color to all accent components (e.g., card headers, etc.)*/
.debug-wrapper {
  margin-top: 40px;
}
.debug-row {
  margin-top: 10px;
}
.debug-box {
  width: 48.5%;
  background-color: var(--component-background-color);
  color: var(--text-color);
  float: left;
  margin: 5px;
  text-align: center;
  -webkit-box-shadow: 1px 2px 11px -2px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 1px 2px 11px -2px rgba(0, 0, 0, 0.33);
  box-shadow: var(--shadow);
  padding: 20px;
  border-radius: 20px;
  cursor: pointer;
  cursor: hand;
  min-height: 210px;
}
.debug-box h2 {
  padding-top: 10px;
}
.debug-box img {
  width: 10%;
}
.debug-box:hover {
  background-color: var(--hover-color);
  color: var(--hover-text-color);
  cursor: pointer;
  cursor: hand;
}
.debug-box i {
  font-size: 40px;
  margin-bottom: 20px;
}
.debug-end-line {
  padding: 5px;
  background: #999;
  width: 100%;
  margin: 30px 0;
  color: #fff;
  text-align: center;
  font-weight: bold;
  /* right: 0px; */
  /* position: absolute; */
}
.cancelDebugBtn {
  background-color: #000;
  color: #fff;
}
.kai-debug-container {
  width: 100%;
  position: relative;
  background-color: var(--secondary-action-color);
  text-align: center;
  color: #fff;
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
  z-index: 12;
  top: 0;
}
#webview-container #debug-container {
  height: 0px;
}
.cancelDebugBtn i {
  color: #fff;
}
.debug-box p {
  margin-bottom: 0rem;
}
.webview_container_widget .debug-box {
  width: 97%;
}
@media only screen and (max-width: 666px) {
  .debug-box {
    width: 97%;
  }
  .debug-box img {
    width: 15%;
  }
}
