@import '../../../styles/variables';

#header-bar {

  .header-bar-top {
    height: var(--header-bar-top-height);
    border-bottom: 1px solid #d9d9d9;
    padding: 0px 25px 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .language-select-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .language-select-text {
    margin-right: 5px;
  }

  .language-select-icon {
    background-repeat: no-repeat;
    background-size: contain;
    height: 15px;
    width: 15px;
    display: -webkit-inline-box;
    vertical-align: middle;
  }

  .webview-header-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 12;
    &:not(.small){
      height: auto;
    }
  }
  
  .webview-header-wrapper .webview-header {
    // height: calc(var(--header-bar-height) - var(--header-bar-bottom-height));
    height: auto;
    line-height: 50px;
    background-color: var(--component-background-color);
    color: #fff;
    &.avatarOpen{
      height: auto;
    }
  }
  
  .webview-header-wrapper.small .webview-header {
    height: calc(var(--header-bar-height-small)  - var(--header-bar-bottom-height));
    height: auto;
    background-color:var(--component-background-color);
    color: #999;
    &.header_language_enabled{
      float:right;
      margin-left: 0px;
      margin-right: 25px;
      right: 0;
    }
  }

  .webview-header-wrapper .minimize-webview {
    cursor: pointer;
    float: left;
    width: 20px;
    z-index: 1;
    &:focus{
      outline: none;
      box-shadow: 0 0 5px 3px var(--highlight-color);
    }
    &.align_right{
      float:right;
      margin-left: 0px;
      margin-right: 25px;
      right: 0;
    }
  }

  .webview-header-wrapper .minimize-webview-language-select {
    cursor: pointer;
    z-index: 1;
    line-height: 1px;
    &:focus{
      outline: none;
      box-shadow: 0 0 5px 3px var(--highlight-color);
    }
    &.align_right{
      float:right;
      margin-left: 0px;
      margin-right: 25px;
      right: 0;
    }
  }

  .webview-header-wrapper .close-webview-language-select {
    cursor: pointer;
    z-index: 1;
    line-height: 1px;
    &:focus{
      outline: none;
      box-shadow: 0 0 5px 3px var(--highlight-color);
    }
    &.align_right{
      float:right;
      margin-left: 0px;
      margin-right: 25px;
      right: 0;
    }
  }

  .webview-header-wrapper.small .minimize-webview {
    margin-block-start: 13px;
    &.no_avatar {
      margin-block-start: 0px;
    }
  }

  .icon-minimize-webview {
    background-repeat: no-repeat;
    background-size: contain;
    height: 20px;
    width: 20px;
    display: -webkit-inline-box;
    vertical-align: middle;
  }

  .icon-minimize-webview-language-select {
    background-repeat: no-repeat;
    background-size: contain;
    height: 15px;
    width: 15px;
    display: -webkit-inline-box;
  }

  .icon-close-webview-language-select {
    background-repeat: no-repeat;
    background-size: contain;
    height: 15px;
    width: 15px;
    display: -webkit-inline-box;
  }

  .webview-header-wrapper.small .close-webview {
    margin-block-start: 13px;
    &.no_avatar {
      margin-block-start: 0px;
    }
  }

  .webview-header-wrapper .close-webview {
    cursor: pointer;
    float: left;
    width: 20px;
    z-index: 1;
    &:focus{
      outline: none;
      box-shadow: 0 0 5px 3px var(--highlight-color);
    }
    &.align_right{
      float:right;
      margin-left: 0px;
      margin-right: 25px;
      right: 0;
    }
  }

  .icon-close-webview {
    background-repeat: no-repeat;
    background-size: contain;
    height: 20px;
    width: 20px;
    display: -webkit-inline-box;
    vertical-align: middle;
  }

  .webview-header-wrapper .webview-header .right-button {
    position: absolute;
    right: 20px;
  }
  
  .webview-header-wrapper .webview-header .webview-title {
    font-size: 17px;
    text-align: center;
    font-weight: bold;
    line-height: var(--header-bar-height);
  }

  .webview-header-wrapper.small .webview-header .webview-title {    
    line-height: var(--header-bar-height-small);
  }
  
  .webview-header-bottom-padding{
    height: calc(var(--header-bar-bottom-height));
    width: 100%;
    background: linear-gradient(0deg, #ffffff00, #ffffff);
  }

}

.header-bar-buttons-wrapper {
  display: flex;
  gap: 10px;

  &.no_select_language  {
    position: absolute;
    margin-inline-start: 10px;
    margin-inline-end: 25px;
  }
}