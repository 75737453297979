.livechat-notification {
  display: flex;
}
.livechat-notification-container {
  margin: 5px 0px;
  padding: 10px 10px 10px 19px;
  position: relative;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  font-size: var(--text-bubble-font-size);
  max-width: var(--text-bubble-and-button-max-width);
}
.livechat-notification-message-container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
}
.livechat-notification-message-container img {
  width: 19px;
  height: 19px;
}
.livechat-notification-message {
  display: flex;
  flex-direction: column;
  color: #000;
  width: calc(100% - 20px);
}
.livechat-link {
  cursor: pointer;
}
.livechat-video-link {
  font-size: var(--timestamp-font-size);
  cursor: pointer;
}
.livechat-link-disabled {
  cursor: none;
  color: var(--primary-action-color);
  word-break: break-word;
  text-decoration: none !important;
}
.video-icon {
  display: inline-block;
  background-color: #0078D4;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 10px;
}
.video-icon::after {
  content: '\25B6';
  /* Play icon */
  font-size: 10px;
}
