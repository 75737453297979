@import '../../../styles/variables';

.widget-introduction {
  position: absolute;
  bottom: 130px;
  right: 35px;
  padding-right: 5px;
  align-content: flex-end;
  display: flex;
  flex-direction: column;
}
.widget-introduction-message {
  font-family: 'Lato', 'Avenir', Helvetica, Arial, sans-serif;
  font-size: var(--card-header-font-size);

  background-color: var(--component-background-color);
  color: var(--text-color);
  padding: 7px 10px 8px 10px;
  max-width: var(--text-bubble-and-button-max-width);

  border-radius: 10px 10px 0px 10px;
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
}
.widget-introduction-x {
  visibility: hidden;
  width: 12px;
  margin-bottom: 5px;
  align-self: flex-end;
}

.widget-introduction:hover .widget-introduction-x {
  visibility: visible;
}
// .widget-introduction:hover .widget-introduction-message {
//   box-shadow: 1px 2px 11px -2px var(--primary-action-color);
// }


/// Animations

.ease-in-animation {
  opacity: 1;
  -webkit-animation: bounce 1s ease-in .5s 2;
  animation: bounce 1s ease-in .5s 2;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

@keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
    animation-timing-function: cubic-bezier(.215,.61,.355,1);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  40%, 43% {
      -webkit-animation-timing-function: cubic-bezier(.755,.05,.855,.06);
      animation-timing-function: cubic-bezier(.755,.05,.855,.06);
      -webkit-transform: translate3d(0,-30px,0);
      transform: translate3d(0,-30px,0);
  }
  70% {
      -webkit-animation-timing-function: cubic-bezier(.755,.05,.855,.06);
      animation-timing-function: cubic-bezier(.755,.05,.855,.06);
      -webkit-transform: translate3d(0,-15px,0);
      transform: translate3d(0,-15px,0);
  }
  90% {
      -webkit-transform: translate3d(0,-4px,0);
      transform: translate3d(0,-4px,0);
  }
}
