/* Set color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set text-color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set color to all accent components (e.g., card headers, etc.)*/
.live-chat-widget {
  position: absolute;
  width: 100%;
  bottom: 66px;
  height: 0;
  visibility: hidden;
  background-color: #ffffff;
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.16);
  z-index: 100001;
}
.live-chat-widget.live_chat_open_up {
  border-top: 1px solid #cecece;
}
.live-chat-widget.live_chat_open_down {
  transition: var(--transition-live-chat-widget);
  transition-property: bottom;
  visibility: visible;
  height: var(--live-chat-widget-height);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: 1px solid #cecece;
}
.live-chat-widget.live_chat_open_down .inline-popup-wrapper {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
@media screen and (min-width: 435px) {
  .live-chat-widget.live_chat_open_down {
    transition-delay: 0.5s;
    bottom: 7px;
    width: 385px;
    right: 30px;
    position: fixed;
  }
  .webview_container_widget .live-chat-widget.live_chat_open_up {
    display: none;
  }
}
@media screen and (max-width: 435px) {
  .live-chat-widget.live_chat_open_down {
    height: var(--live-chat-widget-height);
    display: none;
  }
  .webview_container_widget .live-chat-widget.live_chat_open_up {
    height: var(--live-chat-widget-height);
    visibility: visible;
    bottom: 0px;
    transition: var(--transition-live-chat-widget);
    transition-property: height;
  }
}
.live-chat-widget.live_chat_open_up:not(.webview_container_widget .live-chat-widget.live_chat_open_up) {
  height: var(--live-chat-widget-height);
  visibility: visible;
  bottom: 0px;
  transition: var(--transition-live-chat-widget);
  transition-property: height;
  z-index: 20;
}
.live-chat-widget-inner-container {
  display: flex;
  height: var(--live-chat-widget-height);
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  justify-content: space-between;
  align-items: center;
}
.live-chat-widget-end-container {
  width: 100%;
  display: flex;
  justify-content: end;
}
.live-chat-widget-feature-container {
  display: flex;
  height: var(--live-chat-widget-height);
  justify-content: flex-start;
  align-items: center;
}
.live-chat-button {
  cursor: pointer;
  user-select: none;
  background-color: #F2F2F2;
  font-size: var(--inline-button-font-size);
  line-height: 35px;
  height: 36px;
  display: inline-flex;
  margin-right: 8px;
  color: #1D202A;
  position: relative;
  outline: none;
  padding-left: 8px;
  padding-right: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.live-chat-button:focus {
  outline: none;
}
.live-chat-end-button {
  display: block;
}
.live-chat-end-button.kai-tooltip .kai-tooltip-text {
  right: -5px !important;
}
.live-chat-end-button.kai-tooltip .kai-tooltip-text::after {
  right: 18px;
  left: initial !important;
}
.live-chat-button:hover {
  background-color: var(--live-chat-btn-hover-color);
  color: var(--hover-text-color);
}
.live-chat-end-call-button,
.live-chat-end-button {
  background-color: var(--live-chat-end-button-color);
}
.live-chat-end-call-button:hover,
.live-chat-end-button:hover {
  background-color: var(--live-chat-end-button-color-hover);
}
.live-chat-mute-button-pressed,
.live-chat-desktop-sharing-button-pressed,
.live-chat-video-button-pressed {
  background-color: var(--select-state-color);
}
.live-chat-mute-button-pressed:hover,
.live-chat-desktop-sharing-button-pressed:hover,
.live-chat-video-button-pressed:hover {
  background-color: var(--hover-color);
}
.live-chat-image {
  width: 35px;
  border-radius: 100%;
}
.live-chat-image img {
  height: 20px;
  width: 20px;
  margin: 8px 0;
}
.live-chat-widget-panel {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  background-color: var(--component-background-color);
  width: 100%;
  height: 0px;
  z-index: 100002;
  transition: all 0.5s ease-in-out;
  transition-property: height;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.live-chat-widget-panel span {
  color: inherit;
}
.live-chat-widget-panel.small_panel {
  height: 475px;
}
.live-chat-widget-panel.big_panel {
  bottom: 0;
  height: calc(100% - var(--bottom-bar-height));
}
@media screen and (min-width: 435px) {
  .live-chat-widget-panel {
    bottom: 7px;
    width: 385px;
    right: 30px;
    position: fixed;
  }
  .live-chat-widget.live_chat_open_up .live-chat-widget-panel {
    width: 100%;
    right: 0;
    bottom: 0;
  }
}
.live-chat-widget.live_chat_open_up .live-chat-widget-panel.big_panel {
  height: calc(100% - var(--bottom-bar-height));
}
@media only screen and (min-height: 750px) and (min-width: 435px) {
  .live-chat-widget-panel.big_panel {
    height: 694px;
  }
}
.live-chat-panel-header {
  height: 40px;
  background-color: var(--primary-action-color);
  display: flex;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.live-chat-panel-header .live-chat-panel-title {
  font-size: var(--text-bubble-size);
  font-weight: bold;
  color: var(--primary-action-text-color);
}
.live-chat-panel-text {
  width: 100%;
}
.live-chat-widget-panel.small_panel .live-chat-panel-header,
.live-chat-widget-panel.big_panel .live-chat-panel-header {
  visibility: visible;
  transition: all 0.1s ease-in;
  transition-delay: 0.1s;
  transition-property: height;
}
.small_panel_header {
  height: 20px;
}
.live-chat-panel-webrtc-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow: hidden;
  visibility: hidden;
}
.live-chat-widget-panel.big_panel .live-chat-panel-webrtc-container {
  visibility: visible;
  overflow: initial;
}
.live-chat-panel-inner-container {
  display: flex;
  flex-direction: column;
  height: 0;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease-in;
  transition-delay: 0.2s;
  transition-property: height;
  justify-content: space-evenly;
}
.live-chat-widget-panel.small_panel .live-chat-panel-inner-container,
.live-chat-widget-panel.big_panel .live-chat-panel-inner-container {
  height: 100%;
}
.live-chat-panel-image {
  margin-top: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.live-chat-panel-image .svg-wrapper {
  color: var(--primary-action-color);
}
.live-chat-panel-subtitle {
  width: 100%;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: bold;
  font-size: var(--font-title-size);
}
.live-chat-panel-settings .live-chat-panel-subtitle {
  margin-top: 0px;
}
.live-chat-panel-description {
  margin: 20px 20px;
  display: flex;
  text-align: center;
  justify-content: center;
}
.live-chat-panel-minimize-button {
  color: var(--live-chat-logo-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 5px 5px 11px;
}
.live-chat-panel-minimize-button:focus {
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}
.live-chat-panel-buttons-container {
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  width: 100%;
  padding: 40px;
  padding-right: 27px;
}
.live-chat-panel-buttons-container .kai-card-button {
  border: var(--card-item-border-size) transparent;
  border-radius: 6px;
  font-size: var(--bottom-bar-font-size);
}
.live-chat-panel-buttons-container .kai-card-button.kai-card-outline-button {
  background-color: var(--primary-action-text-color);
  color: var(--primary-action-color);
  border: var(--card-item-border-size) solid var(--primary-action-color);
  font-size: var(--bottom-bar-font-size);
}
.live-chat-panel-buttons-container .kai-card-button.kai-card-outline-button:hover {
  background-color: var(--hover-suggestion-color);
  color: var(--hover-color);
  border: var(--card-item-border-size) solid var(--hover-color);
}
.live-chat-panel-thumbnail-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  gap: 10px;
}
.live-chat-panel-webrtc-item {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.live-chat-panel-webrtc-item figure {
  margin: 0;
  width: 100%;
  height: 100%;
}
.live-chat-panel-webrtc-item figure video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: none !important;
  background-color: black;
}
.live-chat-panel-webrtc-item figure figcaption {
  position: absolute;
  bottom: 0px;
  background-color: grey;
  background-color: var(--right-message-color);
  color: var(--secondary-action-text-color);
  padding: 0 8px;
  font-size: 14px;
}
.live-chat-panel-webrtc-item.live-chat-panel-local-video {
  position: relative;
  z-index: 25;
  width: 160px;
  height: 100px;
  margin: 10px;
}
.live-chat-panel-webrtc-item.live-chat-panel-remote-video {
  position: relative;
  z-index: 25;
  width: 160px;
  height: 100px;
  cursor: pointer;
  margin: 10px;
}
.live-chat-panel-webrtc-item.live-chat-panel-remote-video.main_video {
  margin: 0;
}
.live-chat-panel-webrtc-item.live-chat-panel-remote-video.main_video figure video {
  object-fit: cover;
  margin: 0;
}
.live-chat-panel-webrtc-item.live-chat-panel-local-desktop {
  position: relative;
  z-index: 25;
  width: 160px;
  height: 100px;
  margin: 10px;
}
.live-chat-panel-webrtc-item.live-chat-panel-remote-desktop {
  position: relative;
  z-index: 25;
  width: 160px;
  height: 100px;
  cursor: pointer;
  margin: 10px;
}
.live-chat-panel-webrtc-item.live-chat-panel-remote-desktop.main_video {
  margin: 0;
}
.live-chat-panel-webrtc-item.live-chat-panel-remote-desktop.main_video figure video {
  object-fit: contain;
  margin: 0;
}
.live-chat-panel-webrtc-item.live-chat-panel-avatar {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-action-color);
}
.live-chat-panel-webrtc-item.main_video {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 21;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.live-chat-panel-webrtc-item.main_video figure {
  margin: 0;
  width: 100%;
  height: 100%;
}
.live-chat-panel-webrtc-item.main_video figure video {
  width: 100%;
  object-fit: contain;
  height: 100%;
}
.live-chat-minimize-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.live-chat-panel-settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  height: 100%;
  width: 100%;
}
.live-chat-panel-settings-item {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 10px;
  padding: 0 30px;
  width: 100%;
}
.live-chat-panel-settings-label {
  font-size: var(--bottom-bar-font-size);
  font-weight: bold;
}
.live-chat-panel-settings-select {
  font-family: 'Lato', 'Avenir', Helvetica, Arial, sans-serif;
  width: 100%;
  height: 30px;
  position: relative;
  border-radius: 5px;
  border: 1px solid #DDDDDD;
  padding: 5px;
  font-size: var(--bottom-bar-font-size);
  font-weight: 400;
  background-color: var(--component-background-color);
}
.live-chat-panel-settings-selected-value {
  padding: 0 5px;
  text-wrap: nowrap;
  overflow: hidden;
  width: calc(100% - 20px);
}
.live-chat-panel-settings-select-options {
  position: absolute;
  background-color: var(--component-background-color);
  width: calc(100% + 2px);
  left: -1px;
  top: 12px;
  /* padding: 0 30px; */
  z-index: 100;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  padding: 5px;
  font-size: var(--bottom-bar-font-size);
  font-weight: 400;
  overflow-y: auto;
  max-height: 100px;
}
.live-chat-panel-settings-select-options li {
  list-style: none;
  padding: 0 5px;
  cursor: pointer;
}
.live-chat-panel-settings-select-options li:hover {
  background-color: var(--hover-suggestion-color);
  border-radius: 5px;
}
.live-chat-panel-settings-select-arrow {
  position: absolute;
  right: 2px;
  color: #959699;
  top: 2px;
}
