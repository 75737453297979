.kai-splash-screen {
    position:absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index:9999;
    background-color:var(--component-background-color);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    & .lottie{
        width:100%;
        height:100%;
    }

    &.completed{
        visibility: hidden;
    }
}

