/* Set color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set text-color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set color to all accent components (e.g., card headers, etc.)*/
#header-bar .header-bar-top {
  height: var(--header-bar-top-height);
  border-bottom: 1px solid #d9d9d9;
  padding: 0px 25px 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#header-bar .language-select-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}
#header-bar .language-select-text {
  margin-right: 5px;
}
#header-bar .language-select-icon {
  background-repeat: no-repeat;
  background-size: contain;
  height: 15px;
  width: 15px;
  display: -webkit-inline-box;
  vertical-align: middle;
}
#header-bar .webview-header-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 12;
}
#header-bar .webview-header-wrapper:not(.small) {
  height: auto;
}
#header-bar .webview-header-wrapper .webview-header {
  height: auto;
  line-height: 50px;
  background-color: var(--component-background-color);
  color: #fff;
}
#header-bar .webview-header-wrapper .webview-header.avatarOpen {
  height: auto;
}
#header-bar .webview-header-wrapper.small .webview-header {
  height: calc(var(--header-bar-height-small) - var(--header-bar-bottom-height));
  height: auto;
  background-color: var(--component-background-color);
  color: #999;
}
#header-bar .webview-header-wrapper.small .webview-header.header_language_enabled {
  float: right;
  margin-left: 0px;
  margin-right: 25px;
  right: 0;
}
#header-bar .webview-header-wrapper .minimize-webview {
  cursor: pointer;
  float: left;
  width: 20px;
  z-index: 1;
}
#header-bar .webview-header-wrapper .minimize-webview:focus {
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}
#header-bar .webview-header-wrapper .minimize-webview.align_right {
  float: right;
  margin-left: 0px;
  margin-right: 25px;
  right: 0;
}
#header-bar .webview-header-wrapper .minimize-webview-language-select {
  cursor: pointer;
  z-index: 1;
  line-height: 1px;
}
#header-bar .webview-header-wrapper .minimize-webview-language-select:focus {
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}
#header-bar .webview-header-wrapper .minimize-webview-language-select.align_right {
  float: right;
  margin-left: 0px;
  margin-right: 25px;
  right: 0;
}
#header-bar .webview-header-wrapper .close-webview-language-select {
  cursor: pointer;
  z-index: 1;
  line-height: 1px;
}
#header-bar .webview-header-wrapper .close-webview-language-select:focus {
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}
#header-bar .webview-header-wrapper .close-webview-language-select.align_right {
  float: right;
  margin-left: 0px;
  margin-right: 25px;
  right: 0;
}
#header-bar .webview-header-wrapper.small .minimize-webview {
  margin-block-start: 13px;
}
#header-bar .webview-header-wrapper.small .minimize-webview.no_avatar {
  margin-block-start: 0px;
}
#header-bar .icon-minimize-webview {
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
  width: 20px;
  display: -webkit-inline-box;
  vertical-align: middle;
}
#header-bar .icon-minimize-webview-language-select {
  background-repeat: no-repeat;
  background-size: contain;
  height: 15px;
  width: 15px;
  display: -webkit-inline-box;
}
#header-bar .icon-close-webview-language-select {
  background-repeat: no-repeat;
  background-size: contain;
  height: 15px;
  width: 15px;
  display: -webkit-inline-box;
}
#header-bar .webview-header-wrapper.small .close-webview {
  margin-block-start: 13px;
}
#header-bar .webview-header-wrapper.small .close-webview.no_avatar {
  margin-block-start: 0px;
}
#header-bar .webview-header-wrapper .close-webview {
  cursor: pointer;
  float: left;
  width: 20px;
  z-index: 1;
}
#header-bar .webview-header-wrapper .close-webview:focus {
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}
#header-bar .webview-header-wrapper .close-webview.align_right {
  float: right;
  margin-left: 0px;
  margin-right: 25px;
  right: 0;
}
#header-bar .icon-close-webview {
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
  width: 20px;
  display: -webkit-inline-box;
  vertical-align: middle;
}
#header-bar .webview-header-wrapper .webview-header .right-button {
  position: absolute;
  right: 20px;
}
#header-bar .webview-header-wrapper .webview-header .webview-title {
  font-size: 17px;
  text-align: center;
  font-weight: bold;
  line-height: var(--header-bar-height);
}
#header-bar .webview-header-wrapper.small .webview-header .webview-title {
  line-height: var(--header-bar-height-small);
}
#header-bar .webview-header-bottom-padding {
  height: calc(var(--header-bar-bottom-height));
  width: 100%;
  background: linear-gradient(0deg, #ffffff00, #ffffff);
}
.header-bar-buttons-wrapper {
  display: flex;
  gap: 10px;
}
.header-bar-buttons-wrapper.no_select_language {
  position: absolute;
  margin-inline-start: 10px;
  margin-inline-end: 25px;
}
