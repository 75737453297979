@import '../../../styles/variables';

#webview-container {
  --kai-card-item-fixed-height: var(--card-item-max-height);
  --kai-card-large-item-fixed-height: var(--card-large-item-max-height);
  --kai-bubble-text-fixed-height: var(--bubble-text-max-height);
}


.kai-card-carousel-wrapper,.kai-select-wrapper,
.kai-card-carousel-pager {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0px 0 10px 0;
  widows: 100%;
  clear: both;
}

section[id^=carousel] .kai-card-carousel-wrapper{
  padding-top:15px;
}

.kai-card-carousel-pager {
 /* display: none;*/
}

#kai-core-components .kai-card-carousel-pager {
  margin: 0;
  flex-wrap: wrap;
}

.kai-card-carousel {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-x: hidden;

  &-overflow-container {
    width: 100%;
  }


  &-nav-left,
  &-nav-right {
    display: inline-block;
    text-align: center;
    &[disabled] {
      opacity: 0.6;
    }
    object-fit: contain;
    user-select: none;
  }


  &-nav-left-container {
    z-index: 10;
    left: 0px;
    position: absolute;
    user-select: none;
    background: #F2F2F2;
    width: 38px;
    height: 38px;
    border-radius: 8px;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5));
    &[disabled] {
      opacity: 0.6;
    }

    &:focus{
      outline: none;
      box-shadow: 0 0 2px 2px var(--highlight-color);
    }

    &:active {
      transform: scale(0.9, 0.9);
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5));
    }

  }

  &-nav-left {
    position: absolute;
    left: 29.33%;
    right: 37.22%;
    top: 17.73%;
    bottom: 20.36%;
    z-index: 10;
  }

  &-nav-right {
    position: absolute;
    left: 37.22%;
    right: 29.33%;
    top: 20.36%;
    bottom: 17.73%;
    z-index: 10;
    transform: scale(-1, 1);
  }

  &-nav-right-container {
    z-index: 10;
    right: 0px;
    position: absolute;
    user-select: none;
    background: #F2F2F2;
    width: 38px;
    height: 38px;
    border-radius: 8px;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5));
    &[disabled] {
      opacity: 0.2;
    }

    &:focus{
      outline: none;
      box-shadow: 0 0 2px 2px var(--highlight-color);
    }

    &:active {
      transform: scale(0.9, 0.9);
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5));
    }
  }
}

.kai-card-wraper {
  display: flex;
  width: max-content;
  margin-left: 45px;

  &.transition {
    transition: transform 350ms ease-out;
  }

  &:focus{
    outline: none;
    box-shadow: 0 0 5px 3px var(--highlight-color);
  }
}

.kai-card-item {
  position: relative;
  margin: 0 10px;
  cursor: pointer;
  background: var(--component-background-color);
  color: var(--text-color);
  z-index: 3;
  border: var(--card-item-border-size) solid #ccc;
  margin: 0 20px 0 0;
  border-radius: 15px;
  height: 100%;
  max-width: var(--card-item-width);
  display: inline-block;
  box-shadow: var(--shadow);

  &.fixed {
    height: var(--card-item-max-height);
  }

  &.adaptive {
    height: initial;
  }

  &:first-child {
    // margin-left: 0;
  }

  &:last-child {
    // margin-right: 0;
  }

  & .kai-card-img img.large {
   // width: 63%;
  }

  & .kai-card-img a {
    display: block;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .medium-gauge-wrapper img{
    min-height: 115px;
  }
  
  max-width: calc(var(--card-item-width) - var(--card-item-border-size) * 2);

  img {
    vertical-align: bottom;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    transition: opacity 150ms linear;
    user-select: none;
    max-width: calc(var(--card-item-width) - var(--card-item-border-size) * 2);
    min-height: 198px;
    width: 100%;
  }

  &-footer {
    border-top: 0;
    width: calc(var(--card-item-width) - var(--card-item-border-size) * 2);
  }

  &:focus{
    outline: none;
    box-shadow: 0 0 5px 3px var(--highlight-color);
  }
}

.kai-card-img {
  text-align: center;
}

.kai-card-title {
  padding: 10px 20px 10px 20px;
  min-width: 300px;
  font-weight: bold;
  font-size: var(--font-title-size);
  color: var(--text-color);
  &.gauge{
    padding-bottom: 0px;
  }
  &:focus{
    outline: none;
    box-shadow: 0 0 5px 3px var(--highlight-color);
  }
}

.kai-card-subtitle {
  padding: 0px 20px 20px 20px;
  color: var(--text-color);
  //min-height: 90px;
  width: inherit;
  font-size: var(--font-sub-title-size);
  &.fixed{
    overflow: hidden;
  }
  .medium-gauge-wrapper{
    text-align: center;
  }
  &.gauge{
    padding-bottom: 5px;
  }
  &:focus{
    outline: none;
    box-shadow: 0 0 5px 3px var(--highlight-color);
  }

  & p {
    color: var(--text-color);
  }
}


.kai-card-button-container {
  position: relative;
  width: 93%;
  bottom: 10px;
  margin: 0 auto;

  &.fixed {
   //position: absolute;
  }
}

.kai-card-button {
  cursor: pointer;
  margin: 0;
  padding: 4px 10px;
  font-size: var(--card-button-font-size);
  color: var(--primary-action-text-color);
  text-align: center;
  overflow: hidden;
  border: var(--card-item-border-size)solid #f1f0f0;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  font-weight: bold;
  border-radius: 0 0 0px 0px;
  background-color: var(--primary-action-color);
  &:focus{
    outline: none;
    box-shadow: 0 0 5px 3px var(--highlight-color);
  }
}

.kai-card-button:hover {
  background-color: var(--hover-color);
  color: var(--hover-text-color);
}

.kai-card-button-container .kai-card-button {
  border-radius: 6px;
  margin: 5px 0;
}

/*
.kai-card-button-container .kai-card-button:last-child {
  border-radius: 6px;
}
*/

.kai-card-carousel-pager-item {
  text-indent: -9999px;
  display: block;
  width: 12px;
  height: 12px;
  margin: 8px 8px;
  outline: 0;
  border-radius: 12px;
  background: #ffffff;
  border: solid 1px #979797;
  &.active {
    background-color: var(--primary-action-color);
    border: solid 1px var(--primary-action-color);
  }
}

#carousel-video iframe {
  width: 90%;
  height: 90%;
  margin: 5%;
}

#carousel-video-mp4 {
  padding: 5%;
  background-color: var(--component-background-color);
}

.kai-video{
  border-top-left-radius: 14px;
	border-top-right-radius: 14px;
}

.kai-card-text-container{
  position: relative;

  &:focus{
    outline: none;
    box-shadow: 0 0 5px 3px var(--highlight-color);
  }
}
.kai-card-subtitle.fixed:before {
  content:'';
  width:98%;
  height:80px;    
  position:absolute;
  left:0;
  bottom:0;
  border-radius: 15px;
  background:linear-gradient(var(--background-gradient-starting-color-rgb) 0px, var(--background-gradient-ending-color-rgb));
}

.card-expand-button-wrapper {
  cursor: pointer;
  user-select: none;
  color: #fff;
  position: absolute;
  bottom: 12px;
  left: 43%;
  line-height:20px;
  &.expanded{
    position: absolute;
    width: 35px;
    height: 20px;
  }

  img {
   min-height: 0px !important;
   width: 35px;
  }
  
  &:focus{
    outline: none;
    box-shadow: 0 0 5px 3px var(--highlight-color);
  }
}

.card-expand-button {
  cursor: pointer;
  padding: 5px;
    position: relative;
    height:18px !important;
    width: 40px;
    //background: var(--primary-action-color);
    //border-radius: 50px !important; 
    &:hover{
      //background: var(--hover-color);
     // color: var(--hover-text-color);
    }
}

.kai-card-medium{
  min-height: 195px;


  &:focus{
    outline: none;
    box-shadow: 0 0 5px 3px var(--highlight-color);
  }
}

//Animate Slide
 .isFinishAnimate {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
 }
