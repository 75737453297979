/* Set color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set text-color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set color to all accent components (e.g., card headers, etc.)*/
.large-card-right {
  display: inline-flex;
  float: right;
  position: relative;
  padding-left: 20px;
  font-weight: bold;
  font-size: 14px;
}
.cust-concierge-highlight-label {
  border-radius: 20px;
  padding: 9.5px;
  min-width: 34px;
  max-width: 200px;
  margin-left: 10px;
  display: table-cell;
  text-align: center;
}
.cust-concierge-highlight-container {
  padding-top: 4px;
  padding-right: 4px;
}
.cust-concierge-highlight-value {
  text-align: center;
  background: var(--primary-action-color);
  border-radius: 20px;
  padding: 5px;
  min-width: 31px;
  min-height: 31px;
  margin-left: 10px;
  position: relative;
  vertical-align: middle;
}
.cust-concierge-subtitle {
  font-style: italic;
  font-weight: 400;
  width: 100%;
  text-align: right;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.14901961), rgba(3, 169, 111, 0.63137255));
}
.cust-concierge-table {
  display: table;
  text-align: center;
  width: 100%;
}
.cust-concierge-table-row {
  display: table-row;
}
.cust-concierge-table-heading {
  display: table-header-group;
}
.cust-concierge-table-cell {
  display: table-cell;
  padding: 3px 10px;
  text-align: center;
}
.cust-concierge-table-head {
  font-weight: bold;
}
.cust-concierge-table-foot {
  display: table-footer-group;
  font-weight: bold;
}
.cust-concierge-table-body {
  display: table-row-group;
}
